import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { roadmapV2Slice, RoadmapV2Slice } from './reducers/roadmapV2Slice.ts';

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
};

// Persisted reducer
const persistedReducer = persistReducer<RoadmapV2Slice>(persistConfig, roadmapV2Slice.reducer);

// Configure the store with middleware settings to handle non-serializable values
export const store = configureStore({
  reducer: {
    roadmapV2: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        ignoredPaths: ['_persist'],
      },
    }),
});

// Define RootState type for the entire state
export type RootState = ReturnType<typeof store.getState>;

// Export the store and persistor
export const persistor = persistStore(store);

export default store;
