import {Practice} from "../../models/Practice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {TrackerItem} from "../../models/Tracker";

export interface RoadmapV2Slice {
  practices: Practice[];
  trackerData: TrackerItem[];
  guideposts: TrackerItem[];
  showTracker: boolean;
  trackerName: string;
  trackerUnit: string;
}

export const initialState: RoadmapV2Slice = {
  practices: [],
  trackerData: [],
  guideposts: [],
  showTracker: false,
  trackerName: '',
  trackerUnit: ''
};


export const roadmapV2Slice = createSlice({
  name: "roadmapV2",
  initialState,
  reducers: {
    addPractice: (state, action: PayloadAction<Practice>) => {
      const index = state.practices.findIndex(
        (practice) => practice.id === action.payload.id
      );
      if (index !== -1) {
        // Replace existing entry
        state.practices[index] = action.payload;
      } else {
        // Add new entry
        state.practices.push(action.payload);
      }
    },
    updatePractice: (state, action: PayloadAction<Practice>) => {
      const index = state.practices.findIndex(
        (practice) => practice.id === action.payload.id
      );
      if (index !== -1) {
        // Replace existing entry
        state.practices[index] = action.payload;
      } else {
        // If not found, you might want to add it or throw an error
        console.warn(
          `Achievable with ID ${action.payload.id} not found.`
        );
      }
    },
    updatePractices:(state, action:PayloadAction<Practice[]>) => (
      {
      ...state,
        practices: action.payload,
      }
    ),
    deletePractice: (state, action: PayloadAction<string>) => {
      state.practices = state.practices.filter(
        (practice) => practice.id !== action.payload
      );
    },
    clearPractices: (state) => {
      state.practices = [];
    },
    updateTrackerData: (state, action) => ({
      ...state,
      trackerData: action.payload,
    }),
    updateGuideposts: (state, action) => ({
      ...state,
      guideposts: action.payload,
    }),
    updateShowTracker:(state, action:PayloadAction<boolean>) => ({
      ...state,
      showTracker: action.payload
    }),
    clearTrackerData: (state) => ({
      ...state,
      trackerData: [],
      guideposts: [],
    }),
    clearGuideposts: (state) => ({
      ...state,
      guideposts: [],
    }),
    clearChecks: (state) => {
      state.practices.forEach((practice) => {
        practice.dailyData = [];
        practice.weeklyData = [];
      });
    },
    updateTrackerName: (state, action: PayloadAction<string>) => ({
      ...state,
      trackerName: action.payload,
    }),
    updateTrackerUnit: (state, action: PayloadAction<string>) => ({
      ...state,
      trackerUnit: action.payload,
    }),
  },
});

export const {
  addPractice,
  clearPractices,
  deletePractice,
  updatePractices,
  updatePractice,
  updateTrackerData,
  clearTrackerData,
  clearChecks,
  updateShowTracker,
  updateTrackerName,
  updateTrackerUnit,
  clearGuideposts,
  updateGuideposts,
} = roadmapV2Slice.actions;
