import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import { hexColorWithOpacity } from '../../utilities/OTColors';
import {selectPractices} from '../../redux/selectors/roadmapV2.ts';
import { themeColor } from '../../TWConstants.ts';
import {Practice} from "../../models/Practice";
import EditIcon from "../Icons/Edit/Edit.tsx";
import DeleteIcon from "../Icons/Delete/Delete.tsx";
import {deletePractice, updatePractices} from "../../redux/reducers/roadmapV2Slice.ts";
import { Reorder, useDragControls } from "framer-motion"
import ReorderIcon from "../Icons/Reorder/Reorder.tsx";

const Table = styled.div`
  font-family: Avenir, sans-serif;
  border-spacing: 0;
  width: ${(props) => `${props.width}px`};
  border-radius: 8px;
  backdrop-filter: blur(2px);
  border: 3px solid ${themeColor};
  overflow: hidden;
`;

const TableRowDark = styled.div`
  background-color: ${themeColor};
  color: ${hexColorWithOpacity('#ffffff', 0.9)};
  width: 100%;
  display: flex;
  align-items: center;
`;

const TableRowTransparent = styled.div`
  background-color: ${hexColorWithOpacity(themeColor, 0.01)};
  color: ${themeColor};
`;

const TableRow = styled.div`
  background-color: ${(props) => (props.isDark ? hexColorWithOpacity(themeColor, 0.75) : hexColorWithOpacity(themeColor, 0.5))};
  color: ${themeColor};
`;

const PracticeHeader = styled.div`
  font-size: 20px;
  height: 62px;
  padding-left: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const TableSubHeader = styled.div`
  font-size: 20px;
  height: 42px;
  font-weight: bold;
  padding-left: 14px;
  text-align: left;
  color: ${themeColor};
  background-color: ${hexColorWithOpacity('#ffffff', 0.6)};
  border-bottom: 3px solid ${themeColor};
  display: flex;
  align-items: center;
`;

const TableData = styled.div<{isLast: boolean}>`
  border-bottom: ${({isLast}) => isLast ? 'none' : `3px solid ${themeColor}`};
  color: ${themeColor};
  font-size:16px;
  height: 32px;
  background-color: ${hexColorWithOpacity('#ffffff', 0.55)};
`;

const PracticeDisplay = styled.div`
    display: flex;
    font-size: 16px;
    color: ${themeColor};
    border: none;
    padding: 4px;
    font-weight: bold;
`

const PracticeDisplayLabel = styled.div`
    text-align: left;
    padding-left: 10px;
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const PracticeDisplayActionButtons = styled.div`
    display: flex;
    align-items: center;
    align-self: start;
    justify-content: end;
    gap: 6px;
    margin-left: auto;
    width: 72px;
`

const AddPracticeButton = styled.button`
    background-color: ${themeColor};
    color: white;
    font-weight: bold;
    height: 26px;
    width: 26px;
    border: none;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-left: 8px;
    cursor: pointer;
`

const PracticeActionButton = styled.button`
    background-color: ${hexColorWithOpacity(themeColor, 0.15)};
    color: ${themeColor};
    height: 22px;
    padding: 0;
    aspect-ratio: 1;
    border: none;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
`

const DragButton = styled.div`
    background-color: ${hexColorWithOpacity(themeColor, 0.1)};
    color: ${themeColor};
    height: 22px;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

interface OTPlannerPracticeProps {
  practice: Practice,
  isLast: boolean,
  setPracticeToEdit: (practice:Practice|undefined)=>void,
  setShowAddEditPracticeModal: (show: boolean)=>void,
}

const OTPlannerPractice = (
  { practice, isLast, setPracticeToEdit, setShowAddEditPracticeModal}: OTPlannerPracticeProps
) => {

  const controls = useDragControls()
  const dispatch = useDispatch();
  const [over, setOver] = useState(false)


  return (
    <Reorder.Item as="div" key={practice.id} value={practice}
                  dragListener={false}
                  dragControls={controls}
    >
    <TableRow
      key={practice.id}
      id={`practice-row-${practice.id}`}
      onMouseEnter={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      >
      <TableData isLast={isLast}>
        <PracticeDisplay>
          <PracticeDisplayLabel>
            {practice.name}
          </PracticeDisplayLabel>
          { over &&
	          <PracticeDisplayActionButtons>
		          <PracticeActionButton onClick={() => {
                if (window.confirm('Are you sure you want to delete this practice?')) {
                  dispatch(deletePractice(practice.id))
                }
              }}>
			          <DeleteIcon color={themeColor} size={15}/>
		          </PracticeActionButton>
		          <PracticeActionButton onClick={() => {
                setShowAddEditPracticeModal(true)
                setPracticeToEdit(practice)
              }}>
			          <EditIcon color={themeColor} size={15}/>
		          </PracticeActionButton>
              <DragButton
	              onPointerDown={(e:React.PointerEvent) => controls.start(e)}
              >
                <ReorderIcon color={themeColor} size={15}/>
              </DragButton>
	          </PracticeDisplayActionButtons>
          }
        </PracticeDisplay>
      </TableData>
    </TableRow>
    </Reorder.Item>
  )
}

interface OTPlannerPracticesProps {
  width?: number,
  setShowAddEditPracticeModal:(show:boolean)=>void;
  setPracticeToEdit:(practice:Practice | undefined)=>void;
}

const OTPlannerPractices = (
  { width = 100, setShowAddEditPracticeModal, setPracticeToEdit } : OTPlannerPracticesProps
) => {

  const dispatch = useDispatch();
  const practices = useSelector(selectPractices);
  const [displayPractices, setDisplayPractices] = useState<Practice[]>(practices);

  useEffect(() => {
    setDisplayPractices(practices)
  }, [JSON.stringify(practices)]);
  useEffect(() => {
    dispatch(updatePractices(displayPractices))
  }, [JSON.stringify(displayPractices)]);

	const renderTableRows = () => {
    return (
      <Reorder.Group as="div" values={displayPractices} onReorder={setDisplayPractices}>
      {displayPractices.map((practice, index) => (
          <OTPlannerPractice
            key={`practice-key-${practice.id}`}
            setShowAddEditPracticeModal={setShowAddEditPracticeModal}
            setPracticeToEdit={setPracticeToEdit}
            practice={practice}
            isLast={index === (practices.length - 1)}
          />
      ))}
    </Reorder.Group>)
	};

  return (
    <div style={{ display: 'inline-block' }}>
      <Table width={width}>
          <TableRowDark>
            <PracticeHeader>Weekly Roadmap</PracticeHeader>
          </TableRowDark>
          <TableRowTransparent>
            <TableSubHeader>
              Practices
              <AddPracticeButton onClick={() => {
                setPracticeToEdit(undefined)
                setShowAddEditPracticeModal(true)
              }}>
                +
              </AddPracticeButton>
            </TableSubHeader>
          </TableRowTransparent>
          {renderTableRows()}
      </Table>
    </div>
  );
}

export default OTPlannerPractices;
