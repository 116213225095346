import React, {useState} from 'react';
import styled from 'styled-components';
import OTPlannerPractices from './Practices/OTPlannerPractices.tsx';
import OTLogo from './OTLogo';
import { hexColorWithOpacity } from '../utilities/OTColors';
import {
  themeColor, topGradientColor,
} from '../TWConstants.ts';

import OTWeightTracker from './OTWeightTracker';
import AddEditPracticeModal from "./Practices/AddEditPracticeModal.tsx";
import {Practice} from "../models/Practice";
import OTPlannerWeekBlock from "./Practices/OTPlannerWeekBlock.tsx";
import usePlannerState from "../hooks/usePlannerState.ts";

const PlannerHolder = styled.div`
  display:flex;
  z-index:100;
  margin-bottom: 24px;
  align-items: start;
  justify-content: center;
  gap: 6px;
`;

const BackgroundImageUrl = 'https://static01.nyt.com/images/2022/09/14/climate/14CLI-FOG-promo2-newsletter/merlin_208497654_be398d47-bd03-4a46-b279-19f7d6d8cd60-superJumbo.jpg';
const Background = styled.div`
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #cccccc;
  background-image: url(${BackgroundImageUrl});
`;

const BackgroundGradient = styled.div`
  height: 100vh;
  z-index:0;
  width: 100vw;
  top: 0;
  position: fixed;
  background: linear-gradient(${hexColorWithOpacity(topGradientColor, 0.95)}, ${hexColorWithOpacity(topGradientColor, 0.05)} 30%);
`;

// const Notes = styled.div`
//   z-index: 1000;
//   border-radius: 12px;
//   background-color: ${hexColorWithOpacity('#ffffff', 0.5)};
//   height: 200px;
//   margin-left: auto;
//   margin-right: auto;
//   width: 1216px;
//   border: 3px solid ${themeColor};
//   font-size: 20px;
//   color: ${themeColor};
//   text-align: left;
//   font-family: 'Avenir', sans-serif;
// `;

const TitleHolder = styled.div`
  min-width:1000px;
  width: 1000px;
  margin: 0 auto;
  padding: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
`;
// https://isotropic.co/hex-color-to-css-filter/
// const Logo = styled.img`
//   height: 65px;
//   float:left;
//   margin-top: 20px;
//   margin-left: 20px;
//   filter: invert(17%) sepia(66%) saturate(1287%) hue-rotate(83deg) brightness(95%) contrast(97%);
// `;

const Quote = styled.div`
  font-family: 'Avenir', sans-serif;
  color: ${themeColor};
  width: 800px;
  margin-left: 20px;
  font-size: 18px;
  display: inline-block;
`;

const Author = styled.span`
  font-family: 'Avenir', sans-serif;
  color: ${hexColorWithOpacity(themeColor, 0.75)};
  font-size: 18px;
`;

const ButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const ClearButton = styled.div`
  border-radius: 6px;
  color: ${themeColor};
  font-weight:bold;
  cursor: pointer;
  font-family: Avenir, sans-serif;
  background-color: ${hexColorWithOpacity('#ffffff', 0.6)};
  backdrop-filter: blur(2px);
  padding: 10px;
  margin: 0 10px;
  text-align: center;
`;


const OTPlanner = () => {

  const {
    showTracker,
    setShowTracker,
    setTrackerDataEmpty,
    setCheckDataEmpty,
  } = usePlannerState()
  const [
    showAddEditPracticeModal,
    setShowAddEditPracticeModal
  ] = useState(false);
  const [
    practiceToEdit,
    setPracticeToEdit
  ] = useState<Practice | undefined>();

  const dateSeedString = '2025-01-06T00:00:00';
  const monday1 = new Date(dateSeedString);
  const monday2 = new Date(dateSeedString);
  const monday3 = new Date(dateSeedString);
  const monday4 = new Date(dateSeedString);

  monday2.setDate(monday2.getDate() + 7);
  monday3.setDate(monday3.getDate() + 14);
  monday4.setDate(monday4.getDate() + 21);

  const headerWidth = 250;
  // const blockWidth = 220;

  return (
    <Background>
      <BackgroundGradient>
        <TitleHolder>
          <OTLogo color={themeColor} />
          <Quote>
            <b>
              &quot;Energy and persistence conquer all things.&quot;
              <br />
              <Author> - Benjamin Franklin</Author>
            </b>
          </Quote>
        </TitleHolder>
        <PlannerHolder>
          <OTPlannerPractices
            width={headerWidth}
            setShowAddEditPracticeModal={setShowAddEditPracticeModal}
            setPracticeToEdit={setPracticeToEdit}
          />
          <OTPlannerWeekBlock monday={monday1.toISOString()} width={200}/>
          <OTPlannerWeekBlock monday={monday2.toISOString()} width={200}/>
          <OTPlannerWeekBlock monday={monday3.toISOString()} width={200}/>
          <OTPlannerWeekBlock monday={monday4.toISOString()} width={200}/>

        </PlannerHolder>
        { showTracker &&
	        <OTWeightTracker width={500} height={240} />
        }
        <ButtonPanel>
          <ClearButton
            onClick={() => {
              if (window.confirm('Are you sure want to clear all your check data?')) {
                setCheckDataEmpty()
              }
            }}
          >
            Clear All Checks
          </ClearButton>
          <ClearButton
            onClick={() => {
              setShowTracker(!showTracker)
            }}
          >
            {showTracker ? 'Hide Tracker' : 'Show Tracker'}
          </ClearButton>
          { showTracker &&
	          <ClearButton
		          onClick={() => {
                if (window.confirm('Are you sure want to clear your tracker data?')) {
                  setTrackerDataEmpty()
                }
              }}
	          >
		          Clear Tracker Data
	          </ClearButton>
          }
        </ButtonPanel>
      </BackgroundGradient>
      { showAddEditPracticeModal &&
        <AddEditPracticeModal
          practice={practiceToEdit}
          closeModal={() => {
            setShowAddEditPracticeModal(false)
          }}
        />
      }
    </Background>
  );
}

export default OTPlanner;
