import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { hexColorWithOpacity } from '../utilities/OTColors';
import { themeColor, guidePostColor } from '../TWConstants.ts';
import GraphToolTip from './GraphTooltip';
import EditIcon from "./Icons/Edit/Edit.tsx";
import usePlannerState from "../hooks/usePlannerState.ts";

const Wrapper = styled.div`
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: 10px auto 30px auto;
  font-family: Avenir, sans-serif;
	position: relative;
	overflow: clip;
	border: 3px solid ${themeColor};
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 20px;
    color: ${themeColor};
`

const ModalTitle = styled.div`
	font-weight: bold;
	font-size: 20px;
	margin-top: 8px;
	color: ${themeColor};
`;

const GraphButtonsHolder = styled.div`
	display: flex;
	align-items: center;	
  margin-top: 10px;
  position: absolute;
  top: 2px;
  right: 10px;
	gap: 8px;

`

const AddButton = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 40px;
	color: white;
	cursor: pointer;
	font-size: 22px;
	font-weight: bold;
	background-color: ${hexColorWithOpacity(themeColor, 0.85)};
	display: flex;
	align-items: center;
	justify-content: center;
`;

const AddGuidepostTopButton = styled(AddButton)`
    background-color: ${hexColorWithOpacity(guidePostColor, 0.85)};
`

const CloseButton = styled(AddButton)`
  transform: rotate(45deg);
  background-color: ${hexColorWithOpacity(themeColor, 0.7)};
	position: absolute;
		top: 10px;
		right: 10px;
`;

const ModalCurtain = styled.div`
	background-color: rgba(0,0,0,0.8);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Modal = styled.div`
	width: 300px;
	height: auto;
	padding: 10px;
	border-radius: 10px;
	background-color: white;
	position: relative;
`;

const ModalFieldHolder = styled.div`
	margin-top: 10px;
`;

const ModalEntryField = styled.input`
  width: 120px;
  font-size: 16px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  font-family: Avenir, sans-serif;
`;

const ModalInfoHeading = styled.div`
	text-align: left;
	font-weight: bold;
`

const ModalInfoField = styled.input`
  width: calc(100% - 10px);
  font-size: 16px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
	font-family: Avenir, sans-serif;
`;


const AddEntryButton = styled.div`
  font-weight: bold;
  font-size: 16px;
	color: white;
	margin-top: 10px;
	background-color: ${hexColorWithOpacity(themeColor, 0.85)};
	cursor: pointer;
	border-radius: 40px;
	padding: 10px 26px;
	display: inline-flex;
`;


const EditTrackerButton = styled(AddButton)`
`

function mergeTrackerAndGuideData(trackerData, guidePostData) {
	// This is just an example approach; you could do a more
	// sophisticated merge that ensures sorted dates, etc.

	// Convert each list into an object keyed by date
	const trackerByDate = {};
	trackerData.forEach(d => {
		const ms = DateTime.fromISO(d.date).toMillis();
		trackerByDate[ms] = { dateMs: ms, trackerValue: d.value };
	});

	const guideByDate = {};
	guidePostData.forEach(d => {
		const ms = DateTime.fromISO(d.date).toMillis();
		guideByDate[ms] = { dateMs: ms, guidepostValue: d.value };
	});

	// Merge them:
	const allDates = new Set([
		...Object.keys(trackerByDate),
		...Object.keys(guideByDate),
	]);

	const merged = [...allDates].map(msStr => {
		const ms = parseInt(msStr, 10);
		return {
			dateMs: ms,
			trackerValue: trackerByDate[ms]?.trackerValue,
			guidepostValue: guideByDate[ms]?.guidepostValue,
		};
	});

	// Sort by dateMs ascending:
	merged.sort((a, b) => a.dateMs - b.dateMs);

	return merged;
}

function OTWeightTracker({ width = 400, height = 350 }) {

  const [showAddEntryModal, setShowAddEntryModal] = useState(false);
	const [showAddGuidepostModal, setShowAddGuidepostModal] = useState(false);
	const [showEditTrackerModal, setShowEditTrackerModal] = useState(false);

	const [trackerEntry, setTrackerEntry] = useState(undefined);
	const [guidePostEntry, setGuidepostEntry] = useState(undefined);
	const [guidePostDateISO, setGuidepostDateISO] = useState(DateTime.now().toFormat("yyyy-MM-dd"));

	const {
		trackerUnit,
		trackerName,
		guidePostData,
		setTrackerName,
		setTrackerUnit,
		trackerData,
		setTrackerData,
		setGuidepostData,
	} = usePlannerState()


	const mergedData = mergeTrackerAndGuideData(trackerData, guidePostData)
	console.log(mergedData)

	const trackerValues = trackerData.map((item) => item.value);
	const guidepostValues = guidePostData.map((item) => item.value);
	const allValues = [...trackerValues, ...guidepostValues];


  const graphMaxPoint = Math.max(...allValues);
  const graphMinPoint = Math.min(...allValues);
  const yDomainTop = graphMaxPoint + 2;
  const yDomainBottom = Math.max(0, graphMinPoint - 2);

  const formatYTick = (tickItem) => tickItem.toFixed(2);
  const formatXAxisDate = (tickItem) => DateTime.fromMillis(tickItem).toFormat('MMM dd');


	return (
    <Wrapper width={width} height={height}>

      <Title>{trackerName} Tracker</Title>
	    <GraphButtonsHolder>
		    <AddGuidepostTopButton
			    onClick={() => {
				    setShowAddGuidepostModal(true);
			    }}
		    >
			    +
		    </AddGuidepostTopButton>
		    <EditTrackerButton
			    onClick={() => {
				    setShowEditTrackerModal(true);
			    }}
		    >
			    <EditIcon color={'white'} size={15}/>
		    </EditTrackerButton>
		    <AddButton
			    onClick={() => {
				    setShowAddEntryModal(true);
			    }}
		    >
			    +
		    </AddButton>
	    </GraphButtonsHolder>
      {(!trackerData || trackerData.length === 0) && <div>Enter {trackerName.toLowerCase()} data to start tracking</div>}
      { (trackerData && trackerData.length > 0) && (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
	        data={mergedData}
          margin={{
            top: 10,
            right: 20,
            left: 30,
            bottom: 14,
          }}
        >

          <CartesianGrid strokeDasharray="3 3" />

          <XAxis
            angle={0}
            tickFormatter={formatXAxisDate}
            domain={['auto', 'auto']}
            type="number"
            dataKey="dateMs"
            scale="time"
            label={{
              value: 'Date',
              dy: 18,
              fontFamily: 'Avenir, sans-serif',
              fontSize: '14px',
              fill: 'rgba(0,0,0,0.8)',
            }}
            style={{
              fontSize: '11px', fontFamily: 'Avenir, sans-serif',
            }}
          />

          <YAxis
            dataKey="trackerValue"
            width={40}
            type="number"
            domain={[yDomainBottom, yDomainTop]}
            tickFormatter={formatYTick}
            label={{
              value: `${trackerName} (${trackerUnit})`,
              angle: -90,
              dx: -35,
              fontSize: '14px',
              fill: 'rgba(0,0,0,0.8)',
            }}
            style={{ fontSize: '10px', fontFamily: 'Avenir, sans-serif' }}
          />
	        <Tooltip content={<GraphToolTip />} />
	        <Line
		        type="monotone"
		        dataKey="trackerValue"
		        stroke={themeColor}
		        strokeWidth={2}
		        activeDot={{
			        r: 8,
			        onClick: (dotProps, e) => {
				        const dotIndex = e.index;
				        const dataPoint = mergedData[dotIndex]


				        const indexInTrackerData = trackerData.findIndex(item => {
					        // Convert the original ISO date to milliseconds
					        const itemMs = DateTime.fromISO(item.date).toMillis();
					        return itemMs === dataPoint.dateMs;
				        });

				        if (indexInTrackerData >= 0 && window.confirm('Do you want to delete this guidepost point?')) {
					        const updatedTrackerData = [...trackerData];
					        updatedTrackerData.splice(indexInTrackerData, 1);
					        setTrackerData(updatedTrackerData)
				        }
			        },
		        }}
	        />
	        <Line
		        type="monotone"
		        dataKey="guidepostValue"
		        stroke={guidePostColor}
		        strokeWidth={2}
		        activeDot={{
			        r: 8,
			        onClick: (dotProps, e) => {
				        const dotIndex = e.index;
								const dataPoint = mergedData[dotIndex]


				        const indexInGuidepostData = guidePostData.findIndex(item => {
					        // Convert the original ISO date to milliseconds
					        const itemMs = DateTime.fromISO(item.date).toMillis();
					        return itemMs === dataPoint.dateMs;
				        });

				        if (indexInGuidepostData >= 0 && window.confirm('Do you want to delete this guidepost point?')) {
					        const updatedGuidepostData = [...guidePostData];
					        updatedGuidepostData.splice(indexInGuidepostData, 1);
					        setGuidepostData(updatedGuidepostData)
				        }
			        },
		        }}
	        />
        </LineChart>

      </ResponsiveContainer>
      )}
	    { showEditTrackerModal && (
				<ModalCurtain>
					<Modal>
						<CloseButton
							onClick={() => {
								setShowEditTrackerModal(false);
							}}
						>
							+
						</CloseButton>
						<ModalTitle>Edit Tracker</ModalTitle>
						<ModalFieldHolder>
							<ModalInfoHeading>
								Name
							</ModalInfoHeading>
							<ModalInfoField
								value={trackerName}
								onChange={(e) => {
									const value = e.target.value;
									setTrackerName(value)
								}}
							/>
						</ModalFieldHolder>
						<ModalFieldHolder>
							<ModalInfoHeading>
								Unit
							</ModalInfoHeading>
							<ModalInfoField
								value={trackerUnit}
								onChange={(e) => {
									const value = e.target.value;
									setTrackerUnit(value)
								}}
							/>
						</ModalFieldHolder>
					</Modal>
				</ModalCurtain>
	    )}

      { showAddEntryModal && (
      <ModalCurtain>
        <Modal>
          <CloseButton
            onClick={() => {
              setShowAddEntryModal(false);
            }}
          >
            +
          </CloseButton>
          <ModalTitle>Add {trackerName} Entry</ModalTitle>
          <ModalFieldHolder>
            <ModalEntryField
              value={trackerEntry}
              type="number"
              onChange={(e) => {
                const floatValue = parseFloat(e.target.value);
                if (floatValue < 0) {
                  setTrackerEntry(undefined);
                  return;
                }
								setTrackerEntry(floatValue);
              }}
            />
            {' '}
	          {trackerUnit}
          </ModalFieldHolder>
          <AddEntryButton
            onClick={() => {
              if (trackerEntry !== undefined) {
                const newTrackerEntry = {
                  value: trackerEntry,
                  date: DateTime.now().toISO(),
                };
								setTrackerData(
									[...trackerData, newTrackerEntry]
								)
                setShowAddEntryModal(false);
                setTrackerEntry(undefined);
              }
            }}
          >
            Add
          </AddEntryButton>
        </Modal>
      </ModalCurtain>
      )}

	    { showAddGuidepostModal && (
		    <ModalCurtain>
			    <Modal>
				    <CloseButton
					    onClick={() => {
						    setShowAddGuidepostModal(false);
					    }}
				    >
					    +
				    </CloseButton>
				    <ModalTitle>Add {trackerName} Guidepost</ModalTitle>
				    <ModalFieldHolder>
					    <ModalEntryField
						    value={guidePostEntry}
						    type="number"
						    onChange={(e) => {
							    const floatValue = parseFloat(e.target.value);
							    if (floatValue < 0) {
								    setGuidepostEntry(undefined);
								    return;
							    }
							    setGuidepostEntry(floatValue);
						    }}
					    />
					    {' '}
					    {trackerUnit}
				    </ModalFieldHolder>
				    <ModalFieldHolder>
					    <ModalEntryField
						    value={guidePostDateISO}
						    type="date"
						    onChange={(e) => {
									const dateString = e.target.value
							    setGuidepostDateISO(dateString);
						    }}
					    />
					    {' '}
					    {trackerUnit}
				    </ModalFieldHolder>
				    <AddEntryButton
					    onClick={() => {
						    if (guidePostEntry !== undefined && guidePostDateISO !== undefined) {
							    const isoString = DateTime.fromFormat(guidePostDateISO, "yyyy-MM-dd").toISO();
							    const newGuidepostEntry = {
								    value: guidePostEntry,
								    date: isoString,
							    };
							    setGuidepostData(
								    [...guidePostData, newGuidepostEntry]
							    )
							    setShowAddGuidepostModal(false);
							    setGuidepostEntry(undefined);
									setGuidepostDateISO(undefined)
						    }
					    }}
				    >
					    Add Guidepost
				    </AddEntryButton>
			    </Modal>
		    </ModalCurtain>
	    )}
    </Wrapper>
  );
}

OTWeightTracker.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default OTWeightTracker;
