import './App.css';
import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import OTPlanner from './Components/OTPlanner.tsx';

import { store, persistor } from './redux/store.ts';

const App:React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <OTPlanner />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
