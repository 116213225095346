import React from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {hexColorWithOpacity} from '../../utilities/OTColors';
import {selectPractices} from '../../redux/selectors/roadmapV2.ts';
import {updatePractice} from '../../redux/reducers/roadmapV2Slice.ts';
import {themeColor} from '../../TWConstants.ts';
import {Practice, PracticeDailyData, PracticeWeeklyData, SpecificDaysMap} from "../../models/Practice";

const Table = styled.table`
    font-family: Avenir, sans-serif;
    border-spacing: 0;
    width: ${(props) => `${props.width}px`};
    backdrop-filter: blur(2px);
    border: 3px solid ${themeColor};
    border-radius: 8px;
    overflow: hidden;
`;

const TableRowTransparent = styled.tr`
    background-color: ${hexColorWithOpacity(themeColor, 0.0)};
    color: ${themeColor};
`;

const TableRowDatesHeader = styled.tr`
    background-color: ${hexColorWithOpacity('#ffffff', 0.4)};
    color: ${themeColor};
    height: 45px;
`;

const TableRow = styled.tr`
    background-color: ${(props) => (props.isDark ? hexColorWithOpacity(themeColor, 0.6) : hexColorWithOpacity(themeColor, 0.5))};
    color: ${themeColor};
`;

const TableHeaderDate = styled.th`
    font-size: 20px;
    height: 57px;
    text-align: center;
    border-bottom: 3px solid ${themeColor};
    background-color: ${hexColorWithOpacity('#ffffff', 0.6)};
    border-top-right-radius: 0;
`;

const DateRange = styled.div`
    font-size: 17px;
    text-align: center;
`;

const TableDataDate = styled.td<{ isLast?: boolean, isLastDay?: boolean, isBlocked?: boolean }>`
    border-right: ${({isLastDay}) => isLastDay ? 'none' : `3px solid ${themeColor}`};
    color: ${themeColor};
    cursor: ${({isBlocked}) => isBlocked ? 'default' : 'pointer'};
    font-size: 16px;
    width: 20px;
    height: 30px;
    border-bottom: ${({isLast}) => isLast ? 'none' : `solid 3px ${themeColor}`};
    border-bottom-right-radius: 0;
    background-color: ${({isBlocked}) => isBlocked ? hexColorWithOpacity('#ffffff', 0.25) : hexColorWithOpacity('#ffffff', 0.5)};
`;

const TableDataWeek = styled.td<{ isLast?: boolean, isLastDay?: boolean }>`
    color: ${themeColor};
    cursor: pointer;
    font-size: 16px;
    height: 30px;
    border-bottom: ${({isLast}) => isLast ? 'none' : `solid 3px ${themeColor}`};
    border-bottom-right-radius: 0;
    background-color: ${hexColorWithOpacity('#ffffff', 0.5)};
`;

const SpecificDayBlockDash = styled.div`
    font-weight: 1000;
    font-size: 18px;
    border-radius: 3px;
    padding: 0 3px;
    width: calc(100% - 6px);
`

interface OTPlannerWeekBlockProps {
  width?: number,
  monday: string,
}

const OTPlannerWeekBlock = ({width = 100, monday}: OTPlannerWeekBlockProps) => {
  const practices = useSelector(selectPractices);
  const dispatch = useDispatch();

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const pMondayDate = new Date(monday);

  const pSundayDate = new Date(monday);
  pSundayDate.setDate(pSundayDate.getDate() + 6);

  const year = pSundayDate.getFullYear();

  const month1 = pMondayDate.getMonth();
  const month2 = pSundayDate.getMonth();

  const day1 = pMondayDate.getDate();
  const day2 = pSundayDate.getDate();

  const dateString = `${months[month1]} ${day1} - ${months[month2]} ${day2}, ${year}`;
  const daysArray = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su'];

  const handleDailyCellToggle = (pPractice: Practice, day: string) => {

    const dataIndex = pPractice.dailyData?.findIndex((p) => p.mondayISO === monday) ?? -1;
    const data = pPractice.dailyData || []
    const existingDailyData = dataIndex !== -1 ? data[dataIndex] : undefined;
    if (existingDailyData) {

      const updatedDailyData: PracticeDailyData = {
        ...existingDailyData,
        // @ts-ignore
        [day]: !existingDailyData[day]
      }

      const updatedDailyDataValues: PracticeDailyData[] = [...(pPractice.dailyData || [])];
      updatedDailyDataValues[dataIndex] = updatedDailyData


      const updatedPractice: Practice = {
        ...pPractice,
        dailyData: updatedDailyDataValues,
      }
      dispatch(updatePractice(updatedPractice))
    } else {
      const dailyDataValues: PracticeDailyData = {
        mondayISO: monday,
        M: false,
        T: false,
        W: false,
        Th: false,
        F: false,
        Sa: false,
        Su: false
      }
      // @ts-ignore
      dailyDataValues[day] = true
      const existingDailyData = pPractice?.dailyData || []
      const updatedPractice = {
        ...pPractice,
        dailyData: [
          ...existingDailyData,
          dailyDataValues
        ],
      } as Practice
      console.log(updatedPractice)
      dispatch(updatePractice(updatedPractice))
    }
  }

  const handleWeeklyCellToggle = (pPractice: Practice) => {
    const existingWeekData = pPractice.weeklyData?.find((p) => p.mondayISO === monday)
    if (existingWeekData) {
      const updatedWeeklyData = pPractice.weeklyData?.filter((p) => p.mondayISO !== monday)
      const updatedPractice = {
        ...pPractice,
        weeklyData: updatedWeeklyData,
      } as Practice
      dispatch(updatePractice(updatedPractice))
    } else {
      const weeklyDataEntry = {
        mondayISO: monday,
        value: true
      } as PracticeWeeklyData
      const existingWeeklyData = pPractice?.weeklyData || []
      const updatedPractice = {
        ...pPractice,
        weeklyData: [
          ...existingWeeklyData,
          weeklyDataEntry
        ],
      } as Practice
      dispatch(updatePractice(updatedPractice))
    }
  }


  // Generate table rows outside of the rendering logic
  const renderTableRows = () => {
    const rows = [];
    const nRows = practices.length;

    for (let i = 0; i < nRows; i += 1) {
      const practice = practices[i]
      if (practice.type === "daily" || practice.type ==="specific-days") {
        const dailyData = practice.dailyData?.filter(p => p.mondayISO === monday)[0]
        rows.push(
          <TableRow
            isDark={(i % 2 === 0)}
            key={`key_data_daily_${practice.id}`}
          >
            {daysArray.map((day) => {
              let dailyValue = false
              // @ts-ignore
              if (dailyData && dailyData[day]) {
                // @ts-ignore
                dailyValue = dailyData[day] as boolean
              }
              const specificDayAllowed = practice.specificDaysAllowed ? practice.specificDaysAllowed[day as keyof SpecificDaysMap] : false
              return (
                <TableDataDate
                  isBlocked={
                  practice.type === "specific-days"
                  && !specificDayAllowed
                }
                  key={`key_data_date_value_${day}_${i}`}
                  onClick={() => {
                    if (
                      practice.type === "specific-days"
                      && !specificDayAllowed
                    ) {
                      return;
                    }

                    handleDailyCellToggle(practice, day)
                  }}
                  isLast={i === nRows - 1}
                  isLastDay={day === 'Su'}
                >
                  {
                    dailyValue ? '✅' : ''
                  }
                  {
                    (!specificDayAllowed && practice.type === "specific-days") &&
                    <SpecificDayBlockDash>-</SpecificDayBlockDash>
                  }
                </TableDataDate>
              );
            })}
          </TableRow>
        );
      } else if (practice.type === "weekly") {
        const weeklyData = practice.weeklyData?.filter(p => p.mondayISO === monday)
        rows.push(
          <TableRow
            isDark={(i % 2 === 0)}
            key={`key_data_weekly_${practice.id}`}
          >
            <TableDataWeek
              colSpan="7"
              key={`key_data_date_value_weekly_task_${i}`}
              onClick={() => {
                handleWeeklyCellToggle(practice)
              }}
              isLast={i === nRows - 1}
            >
              {
                weeklyData && weeklyData.length > 0 ? '✅' : ''
              }
            </TableDataWeek>
          </TableRow>
        );
      }

    }
    return rows;
  };

  return (
    <div style={{display: 'inline-block'}}>
      <Table width={width}>
        <thead>
        <TableRowTransparent>
          <TableHeaderDate colSpan="7">
            <DateRange>{dateString}</DateRange>
          </TableHeaderDate>
        </TableRowTransparent>
        <TableRowDatesHeader>
          {
            daysArray.map((day) => (
              <TableDataDate
                isLastDay={day === "Su"}
                key={`key_data_day_${day}`}
              >
                <b>{day}</b>
              </TableDataDate>
            ))
          }
        </TableRowDatesHeader>
        </thead>
        <tbody>
        {renderTableRows()}
        </tbody>
      </Table>
    </div>
  );
}


export default OTPlannerWeekBlock;
