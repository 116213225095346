import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { hexColorWithOpacity } from '../utilities/OTColors';

const TooltipHolder = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  padding: 10px;
  max-width: 300px;
  border-radius: 6px;
  border: solid 1px ${hexColorWithOpacity('#000000', 0.25)};
`;

const Label = styled.div`
  color: rgba(0,0,0,0.75);
`;

const DateLabel = styled.div`
  color: rgba(0,0,0,0.55);
  font-size: 14px;
  margin-bottom: 4px;
`;

function GraphToolTip({ payload }) {
	// If no data in payload, don’t render a tooltip at all
	if (!payload || !payload.length) return null;

	// The .payload property on each item in "payload" is your original data object
	const firstItem = payload[0];
	if (!firstItem || !firstItem.payload) return null;

	// Your mergedData row is in firstItem.payload
	const { dateMs } = firstItem.payload;
	// Safely parse the date, if dateMs is numeric
	const dateLabel = typeof dateMs === 'number'
		? DateTime.fromMillis(dateMs).toFormat('MMM dd, yyyy')
		: '';

	// Map the Recharts payload array so we can show each line’s data
	// Each item in payload => { dataKey: "trackerValue" or "guidepostValue", value: number, payload: {...} }
	return (
		<TooltipHolder>
			<DateLabel>{dateLabel}</DateLabel>
			{payload.map((item) => {
				// dataKey is "trackerValue" or "guidepostValue"
				// value is that line’s numeric value
				const { dataKey, value } = item;
				// You can customize these labels however you like:
				const labelText = dataKey === 'trackerValue'
					? 'Tracker'
					: dataKey === 'guidepostValue'
						? 'Guidepost'
						: dataKey;

				return (
					<Label key={dataKey}>
						<b>{labelText}:</b>{' '}
						{value != null ? value.toFixed(2) : '--'}
					</Label>
				);
			})}
		</TooltipHolder>
	);
}

GraphToolTip.propTypes = {
	// The "payload" prop Recharts automatically passes your custom tooltip
	payload: PropTypes.array,
};

GraphToolTip.defaultProps = {
	payload: [],
};

export default GraphToolTip;
