import React, {useState, ChangeEvent} from "react";
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import {Practice, PracticeType, SpecificDaysMap} from "../../models/Practice";
import {themeColor} from "../../TWConstants.ts";
import {nanoid} from 'nanoid';
import { useDispatch } from 'react-redux';
import {addPractice, updatePractice} from "../../redux/reducers/roadmapV2Slice.ts";


const Curtain = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  font-family: Avenir, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:2;
`;

const Modal = styled.div`
  width: 460px;
  padding: 15px;
  border-radius: 6px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
`;

const InputLabel = styled.div`
  font-size: 18px;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
`;

const Input = styled.input`
  height: 24px;
  width: calc(100% - 12px);
  font-family: Avenir, sans-serif;
  font-size: 16px;
  padding: 6px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid rgba(0,0,0,0.1);
`;

const Select = styled.select`
  height: 40px;
  width: 100%;
  font-family: Avenir, sans-serif;
  font-size: 16px;
  padding: 6px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid rgba(0,0,0,0.1);
`;

const InputSection = styled.div`
  margin: 14px 0;
  width: 100%;
`;

const SaveButton = styled.button`
  background-color: ${themeColor};
  border: none;
  border-radius: 30px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-family: Avenir, sans-serif;
  padding: 10px 15px;
  flex: 0;
`;

const ErrorMessage = styled.div<{isVisible: boolean}>`
  font-size: 16px;
  color: white;
  text-align: center;
  background-color: #b30030;
  padding: 6px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: ${({isVisible}) => isVisible ? 1 : 0};
`;

const DayChecklistHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
`
const DayChecklistItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
`


export interface AddEditPracticeModalProps {
  closeModal: ()=>void;
  practice?: Practice;
}

const AddEditPracticeModal = (
  {
    closeModal,
    practice
  }: AddEditPracticeModalProps,
) => {


  const dispatch = useDispatch();
  const [practiceName, setPracticeName] = useState(practice ? practice.name : '')
  const [practiceType, setPracticeType] = useState<PracticeType>(
    practice ? practice.type : 'daily')
  const [specificDays, setSpecificDays] = useState<SpecificDaysMap>(
    (practice && practice.specificDaysAllowed) ? practice.specificDaysAllowed : {
      M: false,
      T: false,
      W: false,
      Th: false,
      F: false,
      Sa: false,
      Su: false,
    }
  )
  const [error, setError] = useState("empty")

  const hideErrorDelayed = () => {
    setTimeout(() => {
      setError("empty")
    }, 3000)
  }

  const isFormValid = ():boolean => {
    if (practiceName.length <= 0) {
      setError("Please enter a practice name")
      hideErrorDelayed()
      return false;
    }

    if (
      practiceType === 'specific-days'
    ) {
      if (
        !specificDays.M
        && !specificDays.T
        && !specificDays.W
        && !specificDays.Th
        && !specificDays.F
        && !specificDays.Sa
        && !specificDays.Su
      ) {
        setError("Please select at least one day for a specific days practice")
        hideErrorDelayed()
        return false;
      } else {
        return true
      }
    }
    return true
  }

  const doAddPractice = () => {
    if (!isFormValid()) {
      return;
    }

    const newPractice:Practice = {
      name: practiceName,
      type: practiceType,
      id: nanoid(),
      weeklyData: [],
      dailyData: [],
      specificDaysAllowed: specificDays,
    }
    dispatch(addPractice(newPractice))
    closeModal();
  }

  const doUpdatePractice = (pPractice:Practice) => {
    if (!isFormValid()) {
      return;
    }

    const updatedPractice:Practice = {
      name: practiceName,
      type: practiceType,
      id: pPractice.id,
      weeklyData: pPractice.weeklyData,
      dailyData: pPractice.dailyData,
      specificDaysAllowed: specificDays
    }
    dispatch(updatePractice(updatedPractice))
    closeModal();
  }

  return (
    <Curtain>
      <OutsideClickHandler
        onOutsideClick={() => {
          closeModal();
        }}
      >
        <Modal>
          <Title>
            { practice ? 'Edit Practice' : 'Add Practice'}
          </Title>
          <InputSection>
            <InputLabel>Name</InputLabel>
            <Input
              placeholder="Enter practice name (e.g. Workout)"
              value={practiceName}
              onChange={(e:ChangeEvent<HTMLInputElement>) => {
                setPracticeName(e.target.value);
              }}
            />
          </InputSection>
          <InputSection>
            <InputLabel>
              Practice Type
            </InputLabel>
            <Select
              placeholder="Choose Practice Type"
              value={practiceType}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setPracticeType(e.target.value as PracticeType);
              }}
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="specific-days">Specific Days</option>
            </Select>
          </InputSection>
          { practiceType === 'specific-days' &&
	          <InputSection>
		          <InputLabel>
			          Specific Days
		          </InputLabel>
		          <DayChecklistHolder>
			          <DayChecklistItem>
                  <div>Mon</div>
				          <input
                    type="checkbox"
                    checked={specificDays.M}
                    onChange={() => {
                      setSpecificDays({
                        ...specificDays,
                        M: !specificDays.M
                      })
                    }}
                  />
			          </DayChecklistItem>
			          <DayChecklistItem>
				          <div>Tue</div>
				          <input
                    type="checkbox"
                    checked={specificDays.T}
                    onChange={() => {
                      setSpecificDays({
                        ...specificDays,
                        T: !specificDays.T
                      })
                    }}
                  />
			          </DayChecklistItem>
                <DayChecklistItem>
				          <div>Wed</div>
	                <input
                    type="checkbox"
                    checked={specificDays.W}
                    onChange={() => {
                      setSpecificDays({
                        ...specificDays,
                        W: !specificDays.W
                      })
                    }}
                  />
                </DayChecklistItem>
			          <DayChecklistItem>
				          <div>Thu</div>
				          <input
                    type="checkbox"
                    checked={specificDays.Th}
                    onChange={() => {
                      setSpecificDays({
                        ...specificDays,
                        Th: !specificDays.Th
                      })
                    }}
                  />
			          </DayChecklistItem>
			          <DayChecklistItem>
				          <div>Fri</div>
				          <input
                    type="checkbox"
                    checked={specificDays.F}
                    onChange={() => {
                      setSpecificDays({
                        ...specificDays,
                        F: !specificDays.F
                      })
                    }}
                  />
			          </DayChecklistItem>
			          <DayChecklistItem>
				          <div>Sat</div>
				          <input
                    type="checkbox"
                    checked={specificDays.Sa}
                    onChange={() => {
                      setSpecificDays({
                        ...specificDays,
                        Sa: !specificDays.Sa
                      })
                    }}
                  />
			          </DayChecklistItem>
			          <DayChecklistItem>
				          <div>Sun</div>
				          <input
                    type="checkbox"
                    checked={specificDays.Su}
                    onChange={() => {
                      setSpecificDays({
                        ...specificDays,
                        Su: !specificDays.Su
                      })
                    }}
                  />
			          </DayChecklistItem>
		          </DayChecklistHolder>
	          </InputSection>
          }
          <ErrorMessage isVisible={error !== "empty"}>
            {error}
          </ErrorMessage>
          <SaveButton onClick={() => {
            if (!practice) {
              doAddPractice()
            } else {
              doUpdatePractice(practice)
            }
          }}>
            {practice ? 'Save Practice' : 'Add Practice'}
          </SaveButton>
        </Modal>
      </OutsideClickHandler>
    </Curtain>
  );
};

export default AddEditPracticeModal;
