import {SVGIconProps} from "../SVGIconProps";
import React from "react";

const ReorderIcon = ({
                      color,
                      size,
                      marginRight,
                      marginBottom,
                      marginTop,
                      marginLeft,
                      rotation,
                    }: SVGIconProps): JSX.Element => {
  const sizeString = `${size}px`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="reorder"
      width={sizeString}
      height={sizeString}
      viewBox="0 0 24 24"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        marginLeft: marginLeft || 0,
        marginRight: marginRight || 0,
        marginTop: marginTop || 0,
        marginBottom: marginBottom || 0,
        transform: rotation ? `rotate(${rotation}deg)` : "rotate(0)",
      }}
    >
      <path fill={color}
            d="M8.5 7C9.60457 7 10.5 6.10457 10.5 5 10.5 3.89543 9.60457 3 8.5 3 7.39543 3 6.5 3.89543 6.5 5 6.5 6.10457 7.39543 7 8.5 7zM8.5 14C9.60457 14 10.5 13.1046 10.5 12 10.5 10.8954 9.60457 10 8.5 10 7.39543 10 6.5 10.8954 6.5 12 6.5 13.1046 7.39543 14 8.5 14zM10.5 19C10.5 20.1046 9.60457 21 8.5 21 7.39543 21 6.5 20.1046 6.5 19 6.5 17.8954 7.39543 17 8.5 17 9.60457 17 10.5 17.8954 10.5 19zM15.5 7C16.6046 7 17.5 6.10457 17.5 5 17.5 3.89543 16.6046 3 15.5 3 14.3954 3 13.5 3.89543 13.5 5 13.5 6.10457 14.3954 7 15.5 7zM17.5 12C17.5 13.1046 16.6046 14 15.5 14 14.3954 14 13.5 13.1046 13.5 12 13.5 10.8954 14.3954 10 15.5 10 16.6046 10 17.5 10.8954 17.5 12zM15.5 21C16.6046 21 17.5 20.1046 17.5 19 17.5 17.8954 16.6046 17 15.5 17 14.3954 17 13.5 17.8954 13.5 19 13.5 20.1046 14.3954 21 15.5 21z"></path>
    </svg>
  );
};
export default ReorderIcon;
